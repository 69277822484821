import { css } from '@emotion/react';
import EventImageArea from '@landing/src/home-container/components/EventImageArea';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAudioUtils } from '../utils/audio/hook';
import AnnouncerPreviewArea from './components/AnnouncerPreview';
import CelebrityArea from './components/CelebrityArea';
import FigArea from './components/FigArea';
import IntroArea from './components/IntroArea';
import MainArea from './components/MainArea';
import PreviewArea from './components/PreviewArea';
import SloganArea from './components/SloganArea';

export default function HomeContainer() {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady && router.pathname !== '/') {
      router.replace('/', undefined, { shallow: true });
    }
  }, [router]);

  const getAudioUtils = useAudioUtils();

  useEffect(
    () => () => {
      try {
        // 페이지 이탈 시 모든 음원 정지
        getAudioUtils().stopAll();
      } catch {
        // pass
      }
    },
    [getAudioUtils],
  );

  return (
    <div
      css={css`
        width: 100%;
        background: #f9fafa;
      `}
    >
      {/* 메인 영상 */}
      <MainArea />
      {/* 이벤트 이미지 */}
      {/* <EventImageArea /> */}
      {/* 셀럽 배너 */}
      {/* <CelebrityArea /> */}
      {/* 도슨트 배너 */}
      <FigArea />
      {/* 텍스트 인터렉션 */}
      <SloganArea />
      {/* 아나운서 미리듣기 */}
      <AnnouncerPreviewArea />
      {/* 소개 이미지 */}
      <IntroArea />
      {/* 미리듣기 */}
      <PreviewArea />
    </div>
  );
}
