import { css } from '@emotion/react';
import MainLayout from '@landing/src/components/MainLayout';
import HomeContainer from '@landing/src/home-container/HomeContainer';

export default function Home() {
  return (
    <MainLayout
      css={css`
        && {
          padding: 0;
        }
      `}
    >
      <HomeContainer />
    </MainLayout>
  );
}
