import { useCallback, useEffect, useRef } from 'react';

import type { utils } from '..';

export const useAudioUtils = () => {
  const audioUtilsRef = useRef<(typeof utils)['audio']>();
  useEffect(() => {
    import('.').then((m) => {
      audioUtilsRef.current = m.default;
    });
  }, []);
  return useCallback(() => {
    const { current: audioUtils } = audioUtilsRef;
    if (!audioUtils) {
      throw new Error('audioUtils is not ready');
    }
    return audioUtils;
  }, []);
};
