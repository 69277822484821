import styled from '@emotion/styled';
import theme from '@landing/styles/theme';

type Props = {
  opacity?: number;
  fontWeight?: number | string;
  height?: string;
  gridTemplateColumns?: string;
  isPlaying?: boolean;
};

export const MainTitle = styled.div({
  position: 'absolute',
  top: 100,
  left: '50%',
  width: '100%',
  transform: 'translate(-50%, 0)',

  [theme.gaparea]: {
    padding: '0 50px',
  },
  [theme.laptop]: {
    width: '100%',
  },
  [theme.small]: {
    top: 60,
  },

  '& h1': {
    fontFamily: 'Y_Spotlight',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: '100%',
    marginBottom: 30,
    textAlign: 'center',
    wordBreak: 'keep-all',
    '& span': {
      fontFamily: 'Y_Spotlight',
      background: 'linear-gradient(90deg, #4E2BF4 0%, #FF21F6 100%)',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    },

    [theme.laptop]: {
      fontSize: '2rem',
      textAlign: 'center',
    },

    [theme.tablet]: {
      fontSize: '1.8rem',
    },

    [theme.mobile]: {
      lineHeight: 1.4,
    },
  },

  '& p': {
    fontWeight: 300,
    fontSize: '1.6rem',
    textAlign: 'center',

    [theme.laptop]: {
      fontSize: '1.3rem',
      textAlign: 'center',
    },

    [theme.tablet]: {
      fontSize: '1rem',
    },

    [theme.mobile]: {
      margin: '0 16px',
    },
  },
});

export const Tab = styled.div({
  margin: '80px auto',
  display: 'flex',
  justifyContent: 'center',

  [theme.laptop]: {
    justifyContent: 'center',
  },

  [theme.mobile]: {
    margin: '30px 0',
  },
});

export const TabButton = styled.button({
  background: 'transparent',
  fontSize: '17px',
  marginRight: 34,
  [theme.mobile]: {
    fontSize: '15px',
    margin: '0 15px',
  },
});

export const VoiceFontList = styled.div(
  {
    display: 'grid',
    width: '100%',
    justifyItems: 'center',
    [theme.laptop]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.mobile]: {
      gridTemplateColumns: '1fr',
    },
  },
  (props: Props) => ({
    gridTemplateColumns: props.gridTemplateColumns || '1fr 1fr 1fr',
  }),
);

export const ProgressBar = styled.div({
  width: '70%',
  height: '7px',
  background: 'rgba(255,255,255,0.73)',
  position: 'absolute',
  bottom: 0,
  left: '20%',

  '& div': {
    background: '#5464F6',
    width: 0,
    height: '100%',
    animationTimingFunction: 'linear',
  },

  '@keyframes progress': {
    from: { width: 0 },
    to: { width: '100%' },
  },
});

export const Container = styled.div({
  backgroundImage: "url('/images/img_main5.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '900px',
  paddingTop: 400,
  [theme.gaparea]: {
    padding: '400px 50px 0',
  },
  [theme.laptop]: {
    height: '1150px',
  },
  [theme.mobile]: {
    height: '1800px',
    paddingTop: '350px',
  },
});

export const ContentContainer = styled.div({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 21px',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '75px',
  [theme.laptop]: {
    padding: '0 8px',
  },
});

export const ContentBox = styled.div({
  marginLeft: '150px',

  '& h3': {
    fontSize: 18,
    fontWeight: 700,
    margin: 0,
    marginBottom: 6,
  },

  '& p': {
    color: '#373737',
    fontWeight: 300,
  },
});

/*
BOX COMPONENT --- div
*/

export const VoiceFontBox = styled.div({
  position: 'relative',
  maxWidth: '405px',
  height: '150px',
  marginBottom: '80px',
  background: 'rgba(255,255,255,0.34)',
  borderRadius: '75px',

  [theme.laptop]: {
    width: '100%',
  },
});

export const ImageBox = styled.div(
  {
    width: '150px',
    position: 'absolute',
    borderRadius: '75px',
    overflow: 'hidden',
    left: 0,
    bottom: 0,
  },
  (props: Props) => ({
    transition: '0.5s',
    height: props.isPlaying ? '200px' : '150px',
    borderRadius: props.isPlaying ? '0 0 75px 75px' : '75px',
  }),
);

export const FontImage = styled.div({
  position: 'absolute',
  top: '80%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
  transition: '0.5s',
});

export const RefactorFontImage = styled.div({
  position: 'absolute',
  top: '60%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
  transition: '0.5s',
});

export const ShadowBox = styled.div({
  position: 'absolute',
  bottom: '-43px',
  left: '65px',
});
