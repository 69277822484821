import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MainLayoutContainer from '@landing/src/global-components/MainLayoutContainer';
import theme from '@landing/styles/theme';

import { Figure, FlexLayout, StickyBox } from './styles';

const SloganText = styled.div`
  color: #3c64ff;
  font-size: 50px;
  font-family: 'Y_Spotlight';
  font-weight: 400;
  word-break: keep-all;
  ${theme.laptop} {
    font-size: 45px;
  }
  ${theme.tablet} {
    font-size: 35px;
  }
  ${theme.mobile} {
    font-size: 25px;
  }
`;

export default function SloganArea() {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <MainLayoutContainer>
        <FlexLayout>
          <StickyBox>
            <SloganText>지금 가입하고,</SloganText>
            <SloganText>생생한 감정을 담은</SloganText>
            <SloganText>
              <span
                css={css`
                  font-family: 'Y_Spotlight';
                  font-weight: 600;
                `}
              >
                AI 목소리를 무료로 만나보세요!
              </span>
            </SloganText>
          </StickyBox>
          <Figure>
            <div
              css={css`
                width: 85%;
              `}
            >
              <img alt="슬로건 이미지" src="/images/main_01.png" />
            </div>
          </Figure>
        </FlexLayout>
      </MainLayoutContainer>
    </div>
  );
}
