import { css } from '@emotion/react';
import { useAudioUtils } from '@landing/src/utils/audio/hook';
import { useState } from 'react';

import { DATA1, DATA2, DATA3, DATA4, DATA5 } from '../../data';
import BestVoiceFontList from './BestVoiceFontList';
import { MainTitle, Tab, TabButton } from './styles';

const dataRecord = {
  NEW: DATA5,
  캐릭터: DATA1,
  걸스: DATA2,
  키즈: DATA3,
  외국인: DATA4,
} as const;

export default function PreviewArea() {
  const getAudioUtils = useAudioUtils();

  const [currentLabel, setCurrentLabel] =
    useState<keyof typeof dataRecord>('NEW');

  return (
    <div
      css={css`
        width: 100%;
        position: relative;
        overflow: hidden;
      `}
    >
      <MainTitle>
        <h1>
          감정을 표현하는
          <span> AI 보이스</span>
        </h1>

        <p>
          Voice Studio는 오디오북, 영화 해설을 포함해 다양한 분야에 활용할 수
          있는 목소리를 제공합니다.
        </p>

        <Tab>
          {Object.keys(dataRecord).map((label) => (
            <TabButton
              key={label}
              css={css`
                opacity: ${label === currentLabel ? 1 : 0.4};
                font-weight: ${label === currentLabel ? 800 : 400};
              `}
              onClick={() => {
                if (label === currentLabel) {
                  return;
                }
                getAudioUtils().stop({ id: 'voice' });
                setCurrentLabel(label as keyof typeof dataRecord);
              }}
            >
              {label}
            </TabButton>
          ))}
        </Tab>
      </MainTitle>

      <BestVoiceFontList
        data={dataRecord[currentLabel]}
        currentLabel={currentLabel}
      />
    </div>
  );
}
