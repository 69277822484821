interface VoicePreviewData {
  readonly id: string;
  readonly name: string;
  readonly tag: ReadonlyArray<string>;
  readonly bgcolor: string;
  readonly imgUrl: string;
  readonly audioUrl: string;
  readonly sec: number;
}

export type MainBestFontType = {
  readonly bgcolor: string;
  readonly voices: ReadonlyArray<VoicePreviewData>;
};

export const AUDIO_PREVIEW_ANNOUNCER = [
  [
    {
      name: '김대호',
      src: '/audio/announcer/01.wav',
      imgUrl: '/images/announcer/01.png',
      sec: 21,
    },
    {
      name: '서인',
      src: '/audio/announcer/02.wav',
      imgUrl: '/images/announcer/02.png',
      sec: 28,
    },
    {
      name: '정영한',
      src: '/audio/announcer/03.wav',
      imgUrl: '/images/announcer/03.png',
      sec: 29,
    },
    {
      name: '김정현',
      src: '/audio/announcer/04.wav',
      imgUrl: '/images/announcer/04.png',
      sec: 23,
    },
    {
      name: '이정민',
      src: '/audio/announcer/05.wav',
      imgUrl: '/images/announcer/05.png',
      sec: 35,
    },
    {
      name: '김초롱',
      src: '/audio/announcer/06.wav',
      imgUrl: '/images/announcer/06.png',
      sec: 27,
    },
    {
      name: '이선영',
      src: '/audio/announcer/07.wav',
      imgUrl: '/images/announcer/07.png',
      sec: 29,
    },
    {
      name: '박소영',
      src: '/audio/announcer/08.wav',
      imgUrl: '/images/announcer/08.png',
      sec: 20,
    },
  ],
  [
    {
      name: '차미연',
      src: '/audio/announcer/09.wav',
      imgUrl: '/images/announcer/09.png',
      sec: 17,
    },
    {
      name: '김수지',
      src: '/audio/announcer/10.wav',
      imgUrl: '/images/announcer/10.png',
      sec: 28,
    },
    {
      name: '오승훈',
      src: '/audio/announcer/11.wav',
      imgUrl: '/images/announcer/11.png',
      sec: 25,
    },
    {
      name: '임현주',
      src: '/audio/announcer/12.wav',
      imgUrl: '/images/announcer/12.png',
      sec: 39,
    },
    {
      name: '전종환',
      src: '/audio/announcer/13.wav',
      imgUrl: '/images/announcer/13.png',
      sec: 37,
    },
    {
      name: '이진',
      src: '/audio/announcer/14.wav',
      imgUrl: '/images/announcer/14.png',
      sec: 38,
    },
    {
      name: '김상호',
      src: '/audio/announcer/15.wav',
      imgUrl: '/images/announcer/15.png',
      sec: 30,
    },
    {
      name: '정다희',
      src: '/audio/announcer/16.wav',
      imgUrl: '/images/announcer/16.png',
      sec: 34,
    },
  ],
];

export const AUDIO_PREVIEW_MAIN = {
  'main-01': {
    name: '합성',
    src: '/audio/main/01.wav',
  },
  'main-02': {
    name: '더빙',
    src: '/audio/main/02.wav',
  },
  'main-03': {
    name: '다국어',
    src: '/audio/main/03.wav',
  },
  'main-04': {
    name: '셀럽',
    src: '/audio/main/04.wav',
  },
  'main-05': {
    name: '도슨트',
    src: '/audio/main/05.wav',
  },
} as const;

export const DATA1: MainBestFontType = {
  bgcolor: '#D6DAE6',
  voices: [
    {
      id: 'data-01',
      name: '졸리',
      tag: ['30대', '느릿한', '매력적인', '우아한'],
      bgcolor: '#ACBEF3',
      imgUrl: '/images/character/1/01.png',
      audioUrl: '/audio/character/1/01.wav',
      sec: 12,
    },
    {
      id: 'data-02',
      name: '카이저',
      tag: ['20대', '활기찬', '진지한', '정직한'],
      bgcolor: '#B8B0A6',
      imgUrl: '/images/character/1/02.png',
      audioUrl: '/audio/character/1/02.wav',
      sec: 12,
    },
    {
      id: 'data-03',
      name: '솔져 지나',
      tag: ['30대', '단호한', '열정적인', '진지한'],
      bgcolor: '#D7E7B3',
      imgUrl: '/images/character/1/03.png',
      audioUrl: '/audio/character/1/03.wav',
      sec: 19,
    },
    {
      id: 'data-04',
      name: '상구',
      tag: ['20대', '거친', '당당한', '열정적인'],
      bgcolor: '#EF9192',
      imgUrl: '/images/character/1/04.png',
      audioUrl: '/audio/character/1/04.wav',
      sec: 15,
    },
    {
      id: 'data-05',
      name: '혜진',
      tag: ['30대', '느릿한', '무거운', '지적인'],
      bgcolor: '#F7CD97',
      imgUrl: '/images/character/1/05.png',
      audioUrl: '/audio/character/1/05.wav',
      sec: 13,
    },
    {
      id: 'data-06',
      name: '희옥',
      tag: ['30대', '단호한', '뻣뻣한', '씩씩한'],
      bgcolor: '#F7CD97',
      imgUrl: '/images/character/1/06.png',
      audioUrl: '/audio/character/1/06.wav',
      sec: 11,
    },
  ],
};

export const DATA2: MainBestFontType = {
  bgcolor: '#CCE2F1',
  voices: [
    {
      id: 'data-07',
      name: '선화',
      tag: ['10대', '귀여움', '애교많은', '친절한'],
      bgcolor: '#784E8D',
      imgUrl: '/images/character/2/01.png',
      audioUrl: '/audio/character/2/01.wav',
      sec: 12,
    },
    {
      id: 'data-08',
      name: '송작가',
      tag: ['20대', '단호한', '차분한', '편안한'],
      bgcolor: '#B27BA5',
      imgUrl: '/images/character/2/02.png',
      audioUrl: '/audio/character/2/02.wav',
      sec: 13,
    },
    {
      id: 'data-09',
      name: '수민',
      tag: ['20대', '느릿한', '다정한', '진지한'],
      bgcolor: '#DD5C67',
      imgUrl: '/images/character/2/03.png',
      audioUrl: '/audio/character/2/03.wav',
      sec: 16,
    },
    {
      id: 'data-10',
      name: '핑크캣',
      tag: ['20대', '상큼한', '세련된', '편안한'],
      bgcolor: '#9CD9E9',
      imgUrl: '/images/character/2/04.png',
      audioUrl: '/audio/character/2/04.wav',
      sec: 17,
    },
    {
      id: 'data-11',
      name: '재경',
      tag: ['30대', '가벼운', '다정한', '차분한'],
      bgcolor: '#4B515E',
      imgUrl: '/images/character/2/05.png',
      audioUrl: '/audio/character/2/05.wav',
      sec: 16,
    },
    {
      id: 'data-12',
      name: '헤이제이',
      tag: ['20대', '세련된', '씩씩한', '활기찬'],
      bgcolor: '#C9D3D6',
      imgUrl: '/images/character/2/06.png',
      audioUrl: '/audio/character/2/06.wav',
      sec: 15,
    },
  ],
};

export const DATA3: MainBestFontType = {
  bgcolor: '#FFE59D',
  voices: [
    {
      id: 'data-13',
      name: '시아',
      tag: ['10대 미만', '귀여운', '따뜻한', '편안한'],
      bgcolor: '#5494C2',
      imgUrl: '/images/character/3/01.png',
      audioUrl: '/audio/character/3/01.wav',
      sec: 18,
    },
    {
      id: 'data-14',
      name: '지수',
      tag: ['10대 미만', '개구쟁이', '귀여운', '애교많은'],
      bgcolor: '#E9BDD1',
      imgUrl: '/images/character/3/02.png',
      audioUrl: '/audio/character/3/02.wav',
      sec: 28,
    },
    {
      id: 'data-15',
      name: 'kiki',
      tag: ['10대 미만', '귀여운', '따뜻한', '애교많은'],
      bgcolor: '#F1B1B2',
      imgUrl: '/images/character/3/03.png',
      audioUrl: '/audio/character/3/03.wav',
      sec: 12,
    },
    {
      id: 'data-16',
      name: '우영',
      tag: ['10대 미만', '귀여운', '따뜻한', '활기찬'],
      bgcolor: '#BB6A71',
      imgUrl: '/images/character/3/04.png',
      audioUrl: '/audio/character/3/04.wav',
      sec: 12,
    },
    {
      id: 'data-17',
      name: '우람',
      tag: ['10대 미만', '개구쟁이', '귀여운', '씩씩한'],
      bgcolor: '#EAD45A',
      imgUrl: '/images/character/3/05.png',
      audioUrl: '/audio/character/3/05.wav',
      sec: 12,
    },
    {
      id: 'data-18',
      name: '하람',
      tag: ['10대 미만', '귀여운', '당당한', '유쾌한'],
      bgcolor: '#E9DAA8',
      imgUrl: '/images/character/3/06.png',
      audioUrl: '/audio/character/3/06.wav',
      sec: 24,
    },
  ],
};

export const DATA4: MainBestFontType = {
  bgcolor: '#D9D7F1',
  voices: [
    {
      id: 'data-19',
      name: 'Hugh',
      tag: ['40대', '느릿한', '믿음직한', '세련된'],
      bgcolor: '#E5CB9F',
      imgUrl: '/images/character/4/01.png',
      audioUrl: '/audio/character/4/01.wav',
      sec: 19,
    },
    {
      id: 'data-20',
      name: '미도리',
      tag: ['20대', '믿음직한', '지적인', '차분한'],
      bgcolor: '#EEE4AB',
      imgUrl: '/images/character/4/02.png',
      audioUrl: '/audio/character/4/02.wav',
      sec: 9,
    },
    {
      id: 'data-21',
      name: '유키짱',
      tag: ['20대', '귀여운', '애교많은', '활기찬'],
      bgcolor: '#FFC4DD',
      imgUrl: '/images/character/4/03.png',
      audioUrl: '/audio/character/4/03.wav',
      sec: 8,
    },
    {
      id: 'data-22',
      name: '히카루',
      tag: ['30대', '부드러운', '친절한', '편안한'],
      bgcolor: '#C4DDFF',
      imgUrl: '/images/character/4/04.png',
      audioUrl: '/audio/character/4/04.wav',
      sec: 6,
    },
    {
      id: 'data-23',
      name: '호연',
      tag: ['30대', '느릿한', '따뜻한', '부드러운'],
      bgcolor: '#377D71',
      imgUrl: '/images/character/4/05.png',
      audioUrl: '/audio/character/4/05.wav',
      sec: 21,
    },
    {
      id: 'data-24',
      name: 'Harry',
      tag: ['30대', '지적인', '믿음직한', '편안한'],
      bgcolor: '#A7E2B1',
      imgUrl: '/images/character/4/06.png',
      audioUrl: '/audio/character/4/06.wav',
      sec: 16,
    },
  ],
};

export const DATA5: MainBestFontType = {
  bgcolor: '#E3F9CE',
  voices: [
    {
      id: 'data-26',
      name: '나원',
      tag: ['30대', '부드러운', '편안한', '지적인'],
      bgcolor: '#ebfff0',
      imgUrl: '/images/character/5/01.png',
      audioUrl: '/audio/character/5/01.wav',
      sec: 20,
    },
    {
      id: 'data-25',
      name: '세정',
      tag: ['30대', '편안한', '정직한', '당당한'],
      bgcolor: '#f9f9f9',
      imgUrl: '/images/character/5/02.png',
      audioUrl: '/audio/character/5/02.wav',
      sec: 31,
    },
    {
      id: 'data-27',
      name: '찬영',
      tag: ['20대', '깔끔한', '지적인', '믿음직한'],
      bgcolor: '#a3c9ff',
      imgUrl: '/images/character/5/03.png',
      audioUrl: '/audio/character/5/03.wav',
      sec: 20,
    },
    {
      id: 'data-28',
      name: '서아',
      tag: ['30대', '당당한', '지적인', '깔끔한'],
      bgcolor: '#ffc776',
      imgUrl: '/images/character/5/04.png',
      audioUrl: '/audio/character/5/04.wav',
      sec: 26,
    },
    {
      id: 'data-29',
      name: '도하',
      tag: ['20대', '활기찬', '다정한', '따뜻한'],
      bgcolor: '#fbf3e5',
      imgUrl: '/images/character/5/05.png',
      audioUrl: '/audio/character/5/05.wav',
      sec: 22,
    },
    {
      id: 'data-30',
      name: '성훈',
      tag: ['30대', '지적인', '차분한', '부드러운'],
      bgcolor: '#94ffe6',
      imgUrl: '/images/character/5/06.png',
      audioUrl: '/audio/character/5/06.wav',
      sec: 27,
    },
  ],
};
