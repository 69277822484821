import styled from '@emotion/styled';
import theme from '@landing/styles/theme';

type Props = {
  opacity?: number;
  fontWeight?: number | string;
  height?: string;
  gridTemplateColumns?: string;
};

export const VoiceFontList = styled.div(
  {
    display: 'grid',
    width: '100%',
    padding: '0 20px',
    justifyItems: 'center',
    rowGap: '120px',
    [theme.medium]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.tablet]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.mobile]: {
      gridTemplateColumns: '1fr',
    },
  },
  (props: Props) => ({
    gridTemplateColumns: props.gridTemplateColumns || '1fr 1fr 1fr 1fr',
  }),
);

export const ProgressBar = styled.div({
  width: '60%',
  height: '7px',
  background: '#fff',
  position: 'absolute',
  bottom: 0,
  left: '20%',

  '& div': {
    background: '#5464F6',
    width: 0,
    height: '100%',
    animationTimingFunction: 'linear',
  },

  '@keyframes progress': {
    from: { width: 0 },
    to: { width: '100%' },
  },
});

export const Container = styled.div({
  backgroundImage: "url('/images/img_main5.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
});

export const ContentBox = styled.div({
  position: 'absolute',
  width: '100%',
  top: 210,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '& h3': {
    paddingLeft: 20,
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
  },

  '& p': {
    color: '#373737',
    fontWeight: 300,
  },
});

/*
BOX COMPONENT --- div
*/

export const VoiceFontBox = styled.div({
  position: 'relative',
  width: '286px',
  height: '194px',
  background: 'rgba(255,255,255,0.34)',
  borderRadius: '100px',
});

export const ImageBox = styled.div(
  {
    width: '286px',
    height: '250px !important',
    position: 'absolute',
    borderBottomLeftRadius: '100px',
    borderBottomRightRadius: '100px',
    overflow: 'hidden',
    left: 0,
    bottom: 0,
  },
  (props: Props) => ({
    height: props.height || '150px',
  }),
);

export const FontImage = styled.div({
  position: 'absolute',
  top: '60%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
  transition: '0.5s',
});

export const ShadowBox = styled.div({
  position: 'absolute',
  bottom: '-43px',
  left: '65px',
});
