import { css } from '@emotion/react';
import AltImage from '@landing/src/common/AltImage';
import { useAudioUtils } from '@landing/src/utils/audio/hook';
import { useState } from 'react';

import type { MainBestFontType } from '../../data';
import {
  Container,
  ContentBox,
  ContentContainer,
  FontImage,
  ImageBox,
  ProgressBar,
  RefactorFontImage,
  ShadowBox,
  VoiceFontBox,
  VoiceFontList,
} from './styles';

interface Props {
  data: MainBestFontType;
  currentLabel: string;
}

export default function BestVoiceFontList(props: Props) {
  const getAudioUtils = useAudioUtils();

  const [currentPreviewId, setCurrentPreviewId] = useState('');

  return (
    <Container
      css={css`
        background: ${props.data.bgcolor};
      `}
    >
      <VoiceFontList>
        {props.data.voices.map((voice) => {
          const isPlaying = currentPreviewId === voice.id;

          return (
            <VoiceFontBox key={voice.id}>
              {/* 정보 */}
              <ContentContainer>
                {/* 하단 재생바 */}
                <ProgressBar>
                  <div
                    css={
                      isPlaying
                        ? css`
                            display: block;
                            animation-name: progress;
                            animation-duration: ${voice.sec}s;
                          `
                        : css`
                            display: none;
                          `
                    }
                  />
                </ProgressBar>

                <ContentBox>
                  {/* 이름 */}
                  <h3>{voice.name}</h3>

                  {/* 설명 태그 */}
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                    `}
                  >
                    {voice.tag.map((val, idx) => (
                      <p key={`${voice.id}${val}`}>
                        {idx > 0 && <span>·</span>}
                        {val}
                      </p>
                    ))}
                  </div>
                </ContentBox>

                {/* 플레이 버튼 */}
                <button
                  type="button"
                  onClick={() => {
                    const audioUtils = getAudioUtils();
                    audioUtils.stopAll();
                    if (!isPlaying) {
                      audioUtils.stop({ id: 'main' });
                      audioUtils.play({
                        id: 'voice',
                        src: voice.audioUrl,
                        onPlay: () => setCurrentPreviewId(voice.id),
                        onStop: () => setCurrentPreviewId(''),
                      });
                    }
                  }}
                  css={css`
                    flex-shrink: 0;
                    background: transparent;
                  `}
                >
                  <AltImage
                    alt=""
                    src={
                      isPlaying
                        ? '/icons/ic_playing.png'
                        : '/icons/ic_play_w.png'
                    }
                    width={32}
                    height={32}
                  />
                </button>
              </ContentContainer>

              {/* 하단 그림자 */}
              <ShadowBox>
                <AltImage
                  alt=""
                  src="/images/img_shadow.png"
                  width={280}
                  height={37}
                />
              </ShadowBox>

              {/* 보이스폰트 캐릭터 이미지 */}
              <ImageBox isPlaying={isPlaying}>
                <div
                  css={css`
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 150px;
                    height: 150px;
                    background: ${voice.bgcolor};
                    border-radius: 75px;
                  `}
                />
                {props.currentLabel === 'NEW' ? (
                  <RefactorFontImage
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      ${isPlaying &&
                      css`
                        top: 55%;
                      `};
                    `}
                  >
                    <img
                      alt=""
                      src={voice.imgUrl}
                      css={css`
                        height: 200px;
                      `}
                    />
                  </RefactorFontImage>
                ) : (
                  <FontImage
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      ${isPlaying &&
                      css`
                        top: 55%;
                      `};
                    `}
                  >
                    <img
                      alt=""
                      src={voice.imgUrl}
                      css={css`
                        height: 200px;
                      `}
                    />
                  </FontImage>
                )}
              </ImageBox>
            </VoiceFontBox>
          );
        })}
      </VoiceFontList>
    </Container>
  );
}
