import { css } from '@emotion/react';
import MainLayoutContainer from '@landing/src/global-components/MainLayoutContainer';
import { useAudioUtils } from '@landing/src/utils/audio/hook';
import theme from '@landing/styles/theme';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import { useState } from 'react';

import { AUDIO_PREVIEW_MAIN } from '../data';
import { FigFigure, FigStickyBox, FlexLayout, Section } from './styles';

export default function FigArea() {
  // const router = useRouter();

  const getAudioUtils = useAudioUtils();

  const [previewId, setPreviewId] = useState<
    keyof typeof AUDIO_PREVIEW_MAIN | undefined
  >();

  return (
    <MainLayoutContainer
      css={css`
        background: white;
        height: 450px;
      `}
    >
      <Section>
        <img src="/images/pattern_06.png" alt="" className="layer_pattern12" />
        <img src="/images/pattern_07.png" alt="" className="layer_pattern13" />
        {/* <img src="/images/pattern_08.png" alt="" className="layer_pattern14" /> */}
        {/* <img src="/images/pattern_09.png" alt="" className="layer_pattern15" /> */}
        <img src="/images/pattern_10.png" alt="" className="layer_pattern16" />
        <img src="/images/pattern_11.png" alt="" className="layer_pattern17" />
      </Section>
      <FlexLayout
        css={css`
          gap: 0 50px;
        `}
      >
        <FigStickyBox
          className="right"
          css={css`
            position: relative;
            @media (max-width: 1200px) {
              margin-top: 1rem;
            }
          `}
        >
          <img
            src="/images/main_08.png"
            alt="보이스 합성 이미지"
            css={css`
              display: none;
              width: 110px;
              position: absolute;
              top: -80px;
              right: -30px;
              ${theme.tablet} {
                display: inline-block !important;
              }
            `}
          />

          <h5 className="blueColor">다국어 AI 도슨트를 소개합니다!</h5>

          <p className="blackColor">
            아나운서 AI 보이스를 활용해
            <br />
            고품질의 AI 도슨트 콘텐츠를 제작해 드립니다.
          </p>

          <div
            css={css`
              display: flex;
              gap: 20px;
              ${theme.tablet} {
                justify-content: center;
              }
              ${theme.mobile} {
                flex-direction: column;
              }
            `}
          >
            {previewId === 'main-05' ? (
              <button
                className="stopButton"
                onClick={() => getAudioUtils().stopAll()}
                type="button"
              >
                그만듣기
              </button>
            ) : (
              <button
                className="blueButton"
                onClick={() => {
                  const audioUtils = getAudioUtils();
                  audioUtils.stopAll();
                  audioUtils.play({
                    id: 'main',
                    src: AUDIO_PREVIEW_MAIN['main-05'].src,
                    onPlay: () => setPreviewId('main-05'),
                    onStop: () => setPreviewId(undefined),
                  });
                }}
                type="button"
              >
                들어보기
              </button>
            )}

            <Link
              href={{
                pathname: '/support/inquiry/create',
                query: { type: 'CREATE' },
              }}
              css={css`
                margin-top: 25px;

                height: 52px;

                background: #afcaff;
                border-radius: 5px;

                color: #1d7aff;
                font-size: 20px;
                ${theme.laptop} {
                  font-size: 15px;
                }
                ${theme.tablet} {
                  font-size: 14px;
                  padding: 7px 50px;
                }

                display: flex;
                align-items: center;
                justify-content: center;
                padding: 7px 20px;
              `}
            >
              제작문의
            </Link>
          </div>
        </FigStickyBox>

        <FigFigure
          css={css`
            align-items: flex-end;
          `}
        >
          <img
            src="/images/main_06.png"
            alt="보이스 합성 이미지"
            css={css`
              ${theme.tablet} {
                display: none !important;
              }
            `}
          />
          <img
            src="/images/main_07.png"
            alt="보이스 합성 이미지"
            css={css`
              display: none !important;
              ${theme.tablet} {
                display: inline-block !important;
              }
            `}
          />
        </FigFigure>
      </FlexLayout>
    </MainLayoutContainer>
  );
}
