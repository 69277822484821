import { css } from '@emotion/react';
import theme from '@landing/styles/theme';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export default function MainLayoutContainer(props: Props) {
  return (
    <div
      className={props.className}
      css={css`
        position: relative;
        width: 100%;
        height: 805px;
        ${theme.tablet} {
          height: auto;
          padding: 80px 20px;
        }
      `}
    >
      {props.children}
    </div>
  );
}
