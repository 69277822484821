import styled from '@emotion/styled';
import theme from '@landing/styles/theme';

export const FlexLayout = styled.div({
  position: 'relative',
  zIndex: 5,
  width: 1350,
  maxWidth: 1350,
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& .visualScale': {
    transform: 'scale(0.9)',
  },

  [theme.laptop]: {
    width: '87%',
  },
  [theme.tablet]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const Figure = styled.figure({
  margin: 0,
  padding: 0,
  height: '100%',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    display: 'inline-block',
    maxWidth: '100%',

    [theme.tablet]: {
      marginTop: 50,
    },
  },
  [theme.medium]: {
    width: '40%',
  },
  [theme.tablet]: {
    width: '80%',
    textAlign: 'center',
  },
});

export const FigFigure = styled.figure({
  margin: 0,
  padding: 0,
  height: '100%',
  width: '45%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    display: 'inline-block',
    maxWidth: '100%',

    [theme.tablet]: {
      marginTop: 50,
    },
  },
  [theme.medium]: {
    width: '50%',
  },
  [theme.tablet]: {
    width: '55%',
    textAlign: 'center',
  },
  [theme.mobile]: {
    width: '80%',
    textAlign: 'center',
  },
});

export const StickyBox = styled.div({
  flex: 1,
  color: 'white',

  [theme.tablet]: {
    textAlign: 'center',
  },

  '& .right': {
    textAlign: 'right',
  },

  '& h1': {
    fontSize: 50,
    fontFamily: 'Y_Spotlight',
    fontWeight: '600',
    margin: 0,
    padding: 0,
    marginBottom: 10,

    [theme.laptop]: {
      fontSize: 40,
    },

    [theme.tablet]: {
      fontSize: 30,
      marginTop: 20,
    },
  },
  '& h5': {
    fontSize: 35,
    fontFamily: 'Y_Spotlight',
    fontWeight: '400',
    margin: 0,
    padding: 0,
    marginBottom: 20,

    [theme.laptop]: {
      fontSize: 30,
    },
    [theme.tablet]: {
      fontSize: 20,
      marginBottom: 20,
    },
    [theme.mobile]: {
      fontSize: '15px',
    },
  },
  '& p': {
    fontSize: 21,
    fontWeight: '400',
    wordBreak: 'keep-all',

    [theme.laptop]: {
      fontSize: 17,
    },
    [theme.tablet]: {
      fontSize: 15,
    },
    [theme.mobile]: {
      fontSize: '15px',
    },
  },
  '& button': {
    padding: '7px 20px',
    borderRadius: 5,
    fontSize: 20,
    marginTop: 25,

    '& img': {
      verticalAlign: -8,
      marginRight: 18,
    },

    [theme.laptop]: {
      fontSize: 15,

      '& img': {
        width: '20px',
        height: 'auto',
        verticalAlign: -6,
        marginRight: 15,
      },
    },
    [theme.tablet]: {
      fontSize: 14,

      '& img': {
        width: '15px',
        height: 'auto',
        verticalAlign: -3,
        marginRight: 12,
      },
    },
  },
  '& .blueColor': {
    color: '#3C64FF',
  },
  '& .blackColor': {
    color: '#000000',
  },
  '& .whiteButton': {
    height: 52,
    color: '#3C64FF',
    backgroundColor: 'white',
  },
  '& .greyButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: 'white',
  },
  '& .blueButton': {
    height: 52,
    color: 'white',
    backgroundColor: '#3C64FF',
  },
  '& .stopButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: '#EEEEEE',
  },
  '& .yellowButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: '#FFEB60',
  },
  '& .inquiryButton': {
    height: 52,
    color: '#3C64FF',
    backgroundColor: 'white',
    border: '1px solid #3C64FF',
  },
  '& .produceButton': {
    height: 52,
    color: '#1D7AFF',
    backgroundColor: '#AFCAFF',
  },
});

export const FigStickyBox = styled.div({
  flex: 1,
  color: 'white',

  [theme.tablet]: {
    textAlign: 'left',
  },

  '& .right': {
    textAlign: 'right',
  },

  '& h1': {
    fontSize: 50,
    fontFamily: 'Y_Spotlight',
    fontWeight: '600',
    margin: 0,
    padding: 0,
    marginBottom: 10,

    [theme.laptop]: {
      fontSize: 40,
    },

    [theme.tablet]: {
      fontSize: 30,
      marginTop: 20,
    },
  },
  '& h5': {
    fontSize: 35,
    fontFamily: 'Y_Spotlight',
    fontWeight: '400',
    margin: 0,
    padding: 0,
    marginBottom: 20,

    [theme.laptop]: {
      fontSize: 30,
    },
    [theme.tablet]: {
      fontSize: 30,
      marginBottom: 20,
    },
    [theme.mobile]: {
      fontSize: '30px',
    },
  },
  '& p': {
    fontSize: 21,
    fontWeight: '400',
    wordBreak: 'keep-all',

    [theme.laptop]: {
      fontSize: 17,
    },
    [theme.tablet]: {
      fontSize: 17,
    },
    [theme.mobile]: {
      fontSize: '17px',
    },
  },
  '& button': {
    padding: '7px 20px',
    borderRadius: 5,
    fontSize: 20,
    marginTop: 25,

    '& img': {
      verticalAlign: -8,
      marginRight: 18,
    },

    [theme.laptop]: {
      fontSize: 15,

      '& img': {
        width: '20px',
        height: 'auto',
        verticalAlign: -6,
        marginRight: 15,
      },
    },
    [theme.tablet]: {
      fontSize: 14,
      padding: '7px 50px',
      '& img': {
        width: '15px',
        height: 'auto',
        verticalAlign: -3,
        marginRight: 12,
      },
    },
  },
  '& .blueColor': {
    color: '#3C64FF',
  },
  '& .blackColor': {
    color: '#000000',
  },
  '& .whiteButton': {
    height: 52,
    color: '#3C64FF',
    backgroundColor: 'white',
  },
  '& .greyButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: 'white',
  },
  '& .blueButton': {
    height: 52,
    color: 'white',
    backgroundColor: '#3C64FF',
  },
  '& .stopButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: '#EEEEEE',
  },
  '& .yellowButton': {
    height: 52,
    color: '#4C4C4E',
    backgroundColor: '#FFEB60',
  },
  '& .inquiryButton': {
    height: 52,
    color: '#3C64FF',
    backgroundColor: 'white',
    border: '1px solid #3C64FF',
  },
  '& .produceButton': {
    height: 52,
    color: '#1D7AFF',
    backgroundColor: '#AFCAFF',
  },
});

export const Section = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',

  [theme.tablet]: {
    display: 'none',
  },

  '& figure': {
    margin: 0,
    padding: 0,

    '& img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
  },
  '& .layer_circle01': {
    position: 'absolute',
    bottom: -170,
    right: 0,
    zIndex: 1,
    width: 367,

    [theme.xlarge]: {
      width: 'calc(367px - 5%)',
    },
    [theme.large]: {
      width: 'calc(367px - 7%)',
    },
  },
  '& .layer_circle02': {
    position: 'absolute',
    bottom: -700,
    left: 0,
    zIndex: 1,
    width: 478,

    [theme.xlarge]: {
      bottom: -600,
      width: 'calc(478px - 7%)',
    },
    [theme.large]: {
      bottom: -550,
      width: 'calc(478px - 10%)',
    },
  },
  '& .layer_circle03': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: 204,

    [theme.xlarge]: {
      width: 'calc(284px - 7%)',
    },
    [theme.large]: {
      width: 'calc(284px - 10%)',
    },
  },
  '& .layer_circle04': {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    width: 397,

    [theme.xlarge]: {
      width: 'calc(397px - 5%)',
    },
    [theme.large]: {
      width: 'calc(397px - 10%)',
    },
  },
  '& .layer_pattern01': {
    position: 'absolute',
    top: 5,
    left: '50%',
    transform: 'translate(-115%, 0)',
    zIndex: 1,
    width: 685,

    [theme.xlarge]: {
      width: 'calc(685px - 7%)',
      transform: 'translate(-140%, 0)',
    },
    [theme.large]: {
      width: 'calc(685px - 10%)',
      transform: 'translate(-180%, 0)',
    },
  },
  '& .layer_pattern02': {
    position: 'absolute',
    bottom: 30,
    right: '25%',
    zIndex: 1,
    width: 74,

    [theme.xlarge]: {
      width: 'calc(74px - 2%)',
    },
  },
  '& .layer_pattern03': {
    position: 'absolute',
    bottom: -100,
    left: '50%',
    transform: 'translate(20%, 0)',
    zIndex: 1,
    width: 119,

    [theme.xlarge]: {
      width: 'calc(119px - 2%)',
    },
  },
  '& .layer_pattern04': {
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: 'translate(-1200%, 0)',
    zIndex: 1,
    width: 66,

    [theme.xlarge]: {
      width: 'calc(66px - 1%)',
      transform: 'translate(-1500%, 0)',
    },
  },
  '& .layer_pattern05': {
    position: 'absolute',
    bottom: -160,
    left: '50%',
    transform: 'translate(-330%, 0)',
    zIndex: 11,
    width: 247,

    [theme.xlarge]: {
      bottom: -120,
      width: 'calc(247px - 4%)',
      transform: 'translate(-430%, 0)',
    },
  },
  '& .layer_pattern06': {
    position: 'absolute',
    top: 0,
    right: '50%',
    transform: 'translate(190%, 0)',
    zIndex: 0,
    width: 323,
  },
  '& .layer_pattern07': {
    position: 'absolute',
    top: 20,
    left: '50%',
    transform: 'translate(-650%, 0)',
    zIndex: 0,
    width: 104,
  },
  '& .layer_pattern08': {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translate(-370%, 0)',
    zIndex: 0,
    width: 160,
  },
  '& .layer_pattern09': {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-165%, 50%)',
    zIndex: 0,
    width: 331,
  },
  '& .layer_pattern10': {
    position: 'absolute',
    bottom: 45,
    left: '50%',
    transform: 'translate(75%, 0)',
    zIndex: 0,
    width: 245,
  },
  '& .layer_pattern11': {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-190%, 0)',
    zIndex: 0,
    width: 374,
  },
  '& .layer_pattern12': {
    position: 'absolute',
    top: 0,
    right: '60%',
    transform: 'translate(190%, 0)',
    zIndex: 0,
    width: 323,
  },
  '& .layer_pattern13': {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-650%, 0)',
    zIndex: 0,
    width: 104,
  },
  '& .layer_pattern14': {
    position: 'absolute',
    top: '30%',
    right: '0%',
    transform: 'translate(-100%, 0)',
    zIndex: 0,
    width: 160,
  },
  '& .layer_pattern15': {
    position: 'absolute',
    bottom: '50%',
    left: '70%',
    transform: 'translate(-165%, 50%)',
    zIndex: 0,
    width: 331,
  },
  '& .layer_pattern16': {
    position: 'absolute',
    bottom: 45,
    left: '50%',
    transform: 'translate(75%, 0)',
    zIndex: 0,
    width: 245,
  },
  '& .layer_pattern17': {
    position: 'absolute',
    bottom: 0,
    left: '60%',
    transform: 'translate(-190%, 0)',
    zIndex: 0,
    width: 374,
  },
});
