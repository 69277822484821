import { css } from '@emotion/react';
import MainLayoutContainer from '@landing/src/global-components/MainLayoutContainer';
import { useAudioUtils } from '@landing/src/utils/audio/hook';
import { useState } from 'react';

import { AUDIO_PREVIEW_MAIN } from '../data';
import { Figure, FlexLayout, Section, StickyBox } from './styles';

export default function IntroArea() {
  const getAudioUtils = useAudioUtils();

  const [previewId, setPreviewId] = useState<
    keyof typeof AUDIO_PREVIEW_MAIN | undefined
  >();

  return (
    <>
      <MainLayoutContainer
        css={css`
          background: #3c64ff;
        `}
      >
        <Section>
          <figure className="layer_circle01">
            <img alt="" src="/images/circle_01.png" />
          </figure>
          <figure className="layer_circle02">
            <img alt="" src="/images/circle_02.png" />
          </figure>
          <figure className="layer_circle03">
            <img alt="" src="/images/circle_04.png" />
          </figure>
          <figure className="layer_circle04">
            <img alt="" src="/images/circle_05.png" />
          </figure>
          <figure className="layer_pattern01">
            <img alt="" src="/images/pattern_01.png" />
          </figure>
          <figure className="layer_pattern02">
            <img alt="" src="/images/pattern_02.png" />
          </figure>
          <figure className="layer_pattern03">
            <img alt="" src="/images/pattern_03.png" />
          </figure>
        </Section>

        <FlexLayout>
          <StickyBox>
            <h1>합성 보이스</h1>
            <h5>
              사람의 목소리를 닮은
              <br />
              자연스러운 음성 합성
            </h5>
            <p>
              옹알이가 완전한 문장이 되기까지,
              <br />
              Voice Studio도 학습을 거듭하며 사람의 목소리를 재현합니다.
              <br />
              인간의 자연스러움마저 배우는 Voice Studio가
              <br />
              음성 합성의 새로운 지평을 열어 나가겠습니다.
            </p>
            {previewId === 'main-01' ? (
              <button
                className="stopButton"
                onClick={() => getAudioUtils().stopAll()}
                type="button"
              >
                <img
                  src="/icons/ic_playing.png"
                  alt=""
                  width="32px"
                  height="32px"
                />
                그만듣기
              </button>
            ) : (
              <button
                className="whiteButton"
                onClick={() => {
                  const audioUtils = getAudioUtils();
                  audioUtils.stopAll();
                  audioUtils.play({
                    id: 'main',
                    src: AUDIO_PREVIEW_MAIN['main-01'].src,
                    onPlay: () => setPreviewId('main-01'),
                    onStop: () => setPreviewId(undefined),
                  });
                }}
                type="button"
              >
                <img
                  src="/icons/ic_play.png"
                  alt="들어보기 아이콘"
                  width="27px"
                  height="31px"
                />
                들어보기
              </button>
            )}
          </StickyBox>

          <Figure>
            <div
              css={css`
                width: 85%;
              `}
            >
              <img
                alt="보이스 합성 이미지"
                src="/images/main_02.png"
                className="visualScale"
              />
            </div>
          </Figure>
        </FlexLayout>
      </MainLayoutContainer>

      <MainLayoutContainer
        css={css`
          background: white;
        `}
      >
        <FlexLayout>
          <Figure>
            <img
              src="/images/main_03.png"
              alt="감정 더빙 이미지"
              className="visualScale"
            />
          </Figure>

          <StickyBox className="right">
            <h1 className="blueColor">감정 더빙</h1>

            <h5 className="blueColor">
              내 감정과 연기까지 담아내는
              <br />
              AI 보이스 감정 변환
            </h5>

            <p className="blueColor">
              Voice Studio는 음성을 합성할 때 기본 제공되는 정서뿐만 아니라,
              <br />
              나만의 감정과 연기까지 담아낼 수 있습니다.
              <br />
              텍스트의 음성 지문을 내 목소리로 녹음하면 원본의 연기와 감정에
              나만의 감성을 입힐 수 있습니다.
            </p>

            {previewId === 'main-02' ? (
              <button
                className="stopButton"
                onClick={() => getAudioUtils().stopAll()}
                type="button"
              >
                <img
                  src="/icons/ic_playing.png"
                  alt=""
                  width="32px"
                  height="32px"
                />
                그만듣기
              </button>
            ) : (
              <button
                className="blueButton"
                onClick={() => {
                  const audioUtils = getAudioUtils();
                  audioUtils.stopAll();
                  audioUtils.play({
                    id: 'main',
                    src: AUDIO_PREVIEW_MAIN['main-02'].src,
                    onPlay: () => setPreviewId('main-02'),
                    onStop: () => setPreviewId(undefined),
                  });
                }}
                type="button"
              >
                <img
                  src="/icons/ic_play_2.png"
                  alt="들어보기 아이콘"
                  width="27px"
                  height="31px"
                />
                들어보기
              </button>
            )}
          </StickyBox>
        </FlexLayout>
      </MainLayoutContainer>

      <MainLayoutContainer
        css={css`
          background: #4c4c4e;
        `}
      >
        <FlexLayout>
          <StickyBox>
            <h1>다국어 보이스</h1>
            <h5>
              글로벌 콘텐츠로의 성장을 돕는
              <br />
              외국어 합성
            </h5>
            <p>
              Voice Studio는 다국어로도 합성이 가능합니다.
              <br />
              외국어에 막혔던 콘텐츠도 이제 세계로 뻗어나갈 수 있습니다.
              <br />
              현재 영어·중국어·일본어를 지원하며, 점차 확대할 예정입니다.
            </p>
            {previewId === 'main-03' ? (
              <button
                className="stopButton"
                onClick={() => getAudioUtils().stopAll()}
                type="button"
              >
                <img
                  src="/icons/ic_playing.png"
                  alt=""
                  width="32px"
                  height="32px"
                />
                그만듣기
              </button>
            ) : (
              <button
                className="greyButton"
                onClick={() => {
                  const audioUtils = getAudioUtils();
                  audioUtils.stopAll();
                  audioUtils.play({
                    id: 'main',
                    src: AUDIO_PREVIEW_MAIN['main-03'].src,
                    onPlay: () => setPreviewId('main-03'),
                    onStop: () => setPreviewId(undefined),
                  });
                }}
                type="button"
              >
                <img
                  src="/icons/ic_play_3.png"
                  alt="들어보기 아이콘"
                  width="27px"
                  height="31px"
                />
                들어보기
              </button>
            )}
          </StickyBox>
          <Figure>
            <div
              css={css`
                width: 85%;
              `}
            >
              <img
                src="/images/main_04.png"
                alt="보이스 합성 이미지"
                className="visualScale"
              />
            </div>
          </Figure>
        </FlexLayout>
      </MainLayoutContainer>
    </>
  );
}
