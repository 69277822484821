import { css } from '@emotion/react';
import theme from '@landing/styles/theme';

export default function MainArea() {
  return (
    <div
      css={css`
        width: 100%;
        height: 40.625vw;
        background: #444;
        position: relative;
        overflow: hidden;
        ${theme.mobile} {
          height: 75vw;
        }
      `}
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        src="/video/main_00.mp4"
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `}
      />
    </div>
  );
}
