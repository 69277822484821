import { css } from '@emotion/react';
import AltImage from '@landing/src/common/AltImage';
import { useAudioUtils } from '@landing/src/utils/audio/hook';
import { useState } from 'react';

import type { AUDIO_PREVIEW_ANNOUNCER } from '../../data';
import {
  Container,
  ContentBox,
  FontImage,
  ImageBox,
  ProgressBar,
  ShadowBox,
  VoiceFontBox,
  VoiceFontList,
} from './styles';

export const AnnouncerPreviewGrid = (props: {
  announcerList: (typeof AUDIO_PREVIEW_ANNOUNCER)[number];
}) => {
  const getAudioUtils = useAudioUtils();

  const [currentPreviewId, setCurrentPreviewId] = useState('');

  return (
    <Container
      css={css`
        flex-shrink: 0;
      `}
    >
      <VoiceFontList>
        {props.announcerList.map((voice) => {
          const isPlaying = currentPreviewId === voice.name;

          return (
            <VoiceFontBox key={voice.name}>
              {/* 하단 그림자 */}
              <ShadowBox>
                <AltImage
                  alt=""
                  src="/images/img_shadow.png"
                  width={148}
                  height={37}
                />
              </ShadowBox>

              {/* 보이스폰트 캐릭터 이미지 */}
              <ImageBox>
                <div
                  css={css`
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 286px;
                    height: 194px;
                    background: #cad1e4;
                    border-radius: 100px;
                  `}
                />
                <FontImage
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    ${isPlaying &&
                    css`
                      top: 50%;
                    `};
                  `}
                >
                  <img
                    alt=""
                    src={voice.imgUrl}
                    css={css`
                      height: 250px;
                    `}
                  />
                </FontImage>
                {/* 하단 재생바 */}
                <ProgressBar>
                  <div
                    css={
                      isPlaying
                        ? css`
                            display: block;
                            animation-name: progress;
                            animation-duration: ${voice.sec}s;
                          `
                        : css`
                            display: none;
                          `
                    }
                  />
                </ProgressBar>
              </ImageBox>
              <ContentBox>
                {/* 이름 */}
                <h3>
                  {voice.name}
                  <span
                    css={css`
                      font-weight: 400;
                      font-size: 14px;
                      margin-left: 6px;
                    `}
                  >
                    아나운서
                  </span>
                </h3>
                {/* 플레이 버튼 */}
                <button
                  type="button"
                  onClick={() => {
                    const audioUtils = getAudioUtils();
                    audioUtils.stopAll();
                    if (!isPlaying) {
                      audioUtils.play({
                        id: 'announcer',
                        src: voice.src,
                        onPlay: () => setCurrentPreviewId(voice.name),
                        onStop: () => setCurrentPreviewId(''),
                      });
                    }
                  }}
                  css={css`
                    flex-shrink: 0;
                    background: transparent;
                  `}
                >
                  <AltImage
                    alt=""
                    src={
                      isPlaying
                        ? '/icons/ic_playing.png'
                        : '/icons/ic_play_announcer.png'
                    }
                    width={32}
                    height={32}
                  />
                </button>
              </ContentBox>
            </VoiceFontBox>
          );
        })}
      </VoiceFontList>
    </Container>
  );
};
